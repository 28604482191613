import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS, SPACERS, MQ } from 'utils/styleHelpers'
import { BUTTON_WIDTH_FULL, BUTTON_WIDTH } from 'utils/formHelpers'

const FileFieldInput = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	& + label {
		background-color: ${({ bg }) => {
			return bg || COLORS.BLACK
		}};
		border: none;
		border-radius: 4px;
		color: ${COLORS.WHITE};
		cursor: pointer;
		font-size: 16px;
		padding: 14px 28px;
		text-align: center;
		width: ${BUTTON_WIDTH};
		${MQ.L} {
			width: ${BUTTON_WIDTH_FULL};
		}
		${MQ.M} {
			width: ${BUTTON_WIDTH_FULL};
		}
	}

	& + label * {
		pointer-events: none;
	}
	&:focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}
`
const FileFieldLabel = styled.label`
	background: ${COLORS.BLACK};
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.DARK_BLACK};
	}
`
const FileField = ({ onChange }) => (
	<div
		css={css`
			margin-top: ${SPACERS.L};
			margin-bottom: ${SPACERS.L};
		`}
	>
		<FileFieldInput id="file" name="file" onChange={onChange} type="file" />
		<FileFieldLabel htmlFor="file">Change Profile Photo</FileFieldLabel>
	</div>
)

FileField.propTypes = {
	onChange: PropTypes.func,
}

export default FileField
