import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import MaskedInput from 'react-text-mask'

export const MaskedInputField = styled(MaskedInput)`
	font-size: inherit;
	line-height: inherit;
	width: 100%;
	box-shadow: rgb(238, 238, 238) 0px 0px 0px 1px inset;
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 8px;
	padding-bottom: 8px;
	color: inherit;
	background-color: transparent;
	font-family: inherit;
	display: inline-block;
	vertical-align: middle;
	-webkit-appearance: none;
	border-radius: 4px;
	margin: 0px;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
`
export function MaskedInputFieldFF({ input, meta, ...rest }) {
	return <MaskedInputField {...input} meta={meta} {...rest} />
}

MaskedInputFieldFF.propTypes = {
	input: PropTypes.any,
	meta: PropTypes.any
}
