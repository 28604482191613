import React from 'react'
import { MaskedInputFieldFF } from './MaskedInputField'

export default function PhoneField(input) {
	return (
		<MaskedInputFieldFF
			mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			{...input}
			placeholder="(999) 999-9999"
			type="tel"
		/>
	)
}
