import React from 'react'
import PropTypes from 'prop-types'
import states from 'utils/states'
import { SelectFieldFF } from './SelectField'

export default function StateSelectField({ defaultState, input, meta }) {
	return (
		<SelectFieldFF {...input} defaultValue={defaultState} tabIndex={-1}>
			{states.map(s => {
				return (
					<option key={s.abbr} value={s.abbr}>
						{s.fullName}
					</option>
				)
			})}
		</SelectFieldFF>
	)
}

StateSelectField.propTypes = {
	defaultState: PropTypes.any,
	input: PropTypes.any,
	meta: PropTypes.any
}
