import firebase from 'firebase/app'
import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Flex, Box } from '@rebass/emotion'
import { InputField, SwitchField } from 'components/FormFields'
import { css } from '@emotion/core'
import track, { TrackingPropType } from 'react-tracking'
import { EVENT_TYPES, TRACKING_ACTIONS } from 'utils/constants'
import Cookies from 'universal-cookie'
import { stampTime, eventDispatch } from 'utils/tracker'
import { ErrorMessage, FormHeader, StyledButton, StyledLink } from 'components/FormStyles'
import { FormBox, FormFlex, FormWrapper, FormWrapperBox } from 'components/FormLayout'
import { INPUT_WIDTH, setLoginFlag, getLoginFlag, unsetLoginFlag } from 'utils/formHelpers'
import Loader from 'components/Loader'
import { navigateToPathHistory } from 'components/PathHistory'
import { COLORS, SPACERS } from 'utils/styleHelpers'
import { authActions, userActions } from 'wrFirebase'
import { SessionContext } from 'components/AuthContext'
const { doCreateUser, validateUsername } = userActions
function RegisterUserForm({ tracking }) {
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null)
	const [firstName, setFirstName] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [lastName, setLastName] = useState('')
	const [passwordOne, setPasswordOne] = useState('')
	const [passwordTwo, setPasswordTwo] = useState('')
	const [username, setUsername] = useState('')
	const [prefersUsername, setPrefersUsername] = useState(false)

	async function isUsernameValid(username) {
		const userValidation = await validateUsername(username)
		const { isValid, message } = userValidation
		if (!isValid) {
			setError({ message })
			return false
		}
		return true
	}

	useEffect(() => {
		if (getLoginFlag()) {
			setIsLoading(truncateSync)
			unsetLoginFlag()
		}
		// TODO: refactor doCreateUser to async and await
		firebase
			.auth()
			.getRedirectResult()
			.then(async result => {
				if (result.user) {
					setIsLoading(truncateSync)
					const { user } = result
					const { uid, displayName, email, emailVerified } = user

					if (!emailVerified) {
						// prevent bots and spam
						setIsLoading(false)
						setError('Your Google Account is not verified')
					} else {
						// getting only one field with a name.  so have to split if there is a space
						const name = displayName.indexOf(' ') >= 0 ? displayName.split(' ') : [displayName]
						const firstName = name[0]
						const lastName = name.length > 1 ? name[1] : ''
						const cookies = new Cookies()
						const usernameCookie = cookies.get('username')
						const prefersUsernameCookie = cookies.get('prefersUsername')
						const username = usernameCookie === 'null' ? null : usernameCookie
						const prefersUsername = prefersUsernameCookie === 'true'
						const isAgeVerified = cookies.get('ageVerified')
						cookies.remove('prefersUsername')
						cookies.remove('username')

						try {
							await doCreateUser({
								id: uid,
								username,
								prefersUsername: prefersUsername || false,
								firstName,
								lastName,
								email,
								provider: 'google',
								isAgeVerified,
							}).then(() => {
								navigateToPathHistory()
							})
						} catch (error) {
							setIsLoading(false)
							setError(error.message)
						}
					}
				}
			})
	}, [])

	const onSubmit = async () => {
		setIsLoading(true)
		try {
			const isValid = await isUsernameValid(username)
			if (!isValid) {
				setIsLoading(false)
				return
			}

			const authUser = await authActions.doCreateUserWithEmailAndPassword(email, passwordOne)
			const {
				user: { uid },
			} = authUser
			// create a user with email
			await doCreateUser({
				id: uid,
				prefersUsername,
				username,
				firstName,
				lastName,
				email,
				provider: 'email',
				isAgeVerified,
			})
			tracking.trackEvent({
				action: TRACKING_ACTIONS.REGISTER_ACTION,
				event: EVENT_TYPES.WR_REGISTER,
				sessionID,
				uid,
				userIp,
				userLocation,
				version,
			})
			navigateToPathHistory()
		} catch (error) {
			console.log(error.message)
			setError({ message: error.message })
		}
	}

	const onFacebookLogin = async event => {
		authActions.doSignInWithFacebook()
		event.preventDefault()
	}

	const onGoogleLogin = async event => {
		if (username === '' && prefersUsername) {
			setError({ message: 'Please provide a unique username' })
			return
		}
		setIsLoading(true)
		if (username) {
			const isValid = await isUsernameValid(username)
			if (!isValid) {
				setIsLoading(false)
				return
			}
		}

		setLoginFlag()

		// pass some custom data through the redire
		// add the username as a cookie just so we can
		const cookies = new Cookies()
		if (username && prefersUsername) {
			cookies.set('username', username)
		}
		cookies.set('prefersUsername', prefersUsername)
		await authActions.doSignInWithGoogle()
		event.preventDefault()
	}

	const onPrefersUsername = () => {
		setPrefersUsername(!prefersUsername)
	}

	const setStateUsernameInput = event => {
		setUsername(event.target.value === '' ? null : event.target.value)
	}

	const setStateFirstNameInput = event => {
		setFirstName(event.target.value)
	}

	const setStateLastNameInput = event => {
		setLastName(event.target.value)
	}

	const setStateEmailInput = event => {
		setEmail(event.target.value)
	}

	const setStatePwOneInput = event => {
		setPasswordOne(event.target.value)
	}

	const setStatePwTwoInput = event => {
		setPasswordTwo(event.target.value)
	}

	const clearErrors = () => {
		setError(null)
	}

	const isUsernameIsInvalid = !username && prefersUsername
	const isInvalid =
		passwordOne !== passwordTwo ||
		passwordOne === '' ||
		email === '' ||
		firstName === '' ||
		lastName === '' ||
		isUsernameIsInvalid
	return (
		<div>
			{isLoading ? (
				<Loader />
			) : (
				<FormWrapper>
					<FormWrapperBox>
						<FormHeader>Register</FormHeader>
						<form>
							<FormFlex>
								<FormBox>
									<div
										css={css`
											font-size: 14px;
										`}
									>
										<Flex
											css={css`
												margin-bottom: ${SPACERS.M};
											`}
											flexDirection="row"
											justifyContent="center"
											alignItems="center"
										>
											<Box
												css={css`
													padding-left: ${SPACERS.S};
													padding-right: ${SPACERS.S};
													margin-right: ${SPACERS.S};
												`}
											>
												<SwitchField
													checked={prefersUsername}
													onChange={onPrefersUsername}
													tabIndex={-1}
												/>
											</Box>
											<Box px={1}>
												Use my username for posts and shares. Otherwise, your first and last
												name will be used. This can be changed later.
											</Box>
										</Flex>
									</div>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStateUsernameInput}
										onFocus={clearErrors}
										placeholder="Username"
										type="text"
										value={username}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStateFirstNameInput}
										onFocus={clearErrors}
										placeholder="First Name"
										type="text"
										value={firstName}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStateLastNameInput}
										onFocus={clearErrors}
										placeholder="Last Name"
										type="text"
										value={lastName}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStateEmailInput}
										onFocus={clearErrors}
										placeholder="Email Address"
										type="text"
										value={email}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStatePwOneInput}
										onFocus={clearErrors}
										placeholder="Password"
										type="password"
										value={passwordOne}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setStatePwTwoInput}
										onFocus={clearErrors}
										placeholder="Confirm Password"
										type="password"
										value={passwordTwo}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<Flex flexDirection="column">
										<Box py={2}>
											<StyledButton
												bg={COLORS.BLACK}
												disabled={isInvalid}
												onClick={onSubmit}
												type="button"
											>
												Sign Up
											</StyledButton>
										</Box>
										<Box py={2}>
											<StyledButton bg={COLORS.RED} onClick={onGoogleLogin} type="button">
												Register With Google
											</StyledButton>
										</Box>
									</Flex>
								</FormBox>
								<FormBox>
									<StyledLink to="/login">Return to Login</StyledLink>
								</FormBox>
								{error && (
									<Box py={1}>
										<ErrorMessage>{error.message}</ErrorMessage>
									</Box>
								)}
							</FormFlex>
						</form>
					</FormWrapperBox>
				</FormWrapper>
			)}
		</div>
	)
}

const RegisterLink = () => (
	<p>
		Don&#39;t have an Account <Link to="/" />
	</p>
)
RegisterUserForm.propTypes = {
	tracking: TrackingPropType,
}
const RegisterForm = track({ timestamp: stampTime() })(RegisterUserForm)

export { RegisterLink, RegisterForm }
