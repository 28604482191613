import React from 'react'
import { BeatLoader } from 'react-spinners'
import { Box, Flex } from '@rebass/emotion'
import { COLORS, SPACERS } from 'utils/styleHelpers'
import styled from '@emotion/styled'

const LoaderOverlay = styled.div`
	background-color: ${COLORS.WHITE};
	height: calc(80vh - ${SPACERS.HEADER});
	.dark & {
		background: ${COLORS.BLACK};
	}
`

const LoaderFlexBox = styled(Flex)`
	height: 100%;
`

const Loader = () => (
	<LoaderOverlay>
		<LoaderFlexBox alignItems="center" flexDirection="column" justifyContent="center">
			<Box align="flex-start">
				<BeatLoader color={COLORS.GREEN} margin="10px" size={24} />
			</Box>
		</LoaderFlexBox>
	</LoaderOverlay>
)

export default Loader
