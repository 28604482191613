import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { COLORS, shadeColor2 } from 'utils/styleHelpers'

const CheckBoxContainer = styled.label`
	appearence: button;
	background-color: ${({ isChecked, bgc, bgcOff }) => (isChecked ? shadeColor2(bgc, 0.5) : bgcOff)};
	border: 1px solid ${({ bgc }) => shadeColor2(bgc, 0.5)};
	border-radius: 1px;
	cursor: pointer;
	display: block;
	margin: 5px 10px;
	padding: 5px 10px;
	width: 25px;
	height: 25px;
	&:after {
		content: '✓';
		font-size: 25px;
		color: ${COLORS.GREEN};
	}
	&:before {
		content: '';
	}
`

const CheckBox = styled.input`
	position: absolute;
	left: -9999px;
	&:checked + label {
		color: red;
	}
`

CheckBoxContainer.propTypes = {
	bgc: PropTypes.string,
	bgcOff: PropTypes.string,
	isChecked: PropTypes.bool,
}
CheckBoxContainer.defaultProps = {
	bgc: COLORS.GREEN,
	bgcOff: COLORS.WHITE,
	isChecked: false,
}

export default class CheckBoxField extends React.PureComponent {
	static propTypes = {
		label: PropTypes.string,
	}

	state = {
		isChecked: false,
	}

	handleChange = () => {
		const { isChecked } = this.state
		// console.log(`handling change :: ${isChecked}`)
		this.setState({ isChecked: !isChecked })
	}

	render() {
		const { label } = this.props
		const { isChecked } = this.state
		return (
			<CheckBoxContainer isChecked={isChecked}>
				<CheckBox controlName={`chkbx___${label}`} onClick={this.handleChange} />
				{/* <input type='checkbox' />
		<label>{label}</label> */}
			</CheckBoxContainer>
		)
	}
}
