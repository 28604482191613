import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { COLORS } from 'utils/styleHelpers'
const TextArea = styled.textarea`
	font-size: inherit;
	line-height: inherit;
	width: 100%;
	box-shadow: rgb(238, 238, 238) 0px 0px 0px 1px inset;
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 8px;
	padding-bottom: 8px;
	color: inherit;
	background: ${COLORS.WHITE};
	font-family: inherit;
	display: inline-block;
	vertical-align: middle;
	-webkit-appearance: none;
	border-radius: 4px;
	margin: 0px;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	transition: background 0.5s, color 0.5s;
	.dark & {
		background: ${COLORS.BLACK};
		color ${COLORS.WHITE};
	}
`

const TextAreaField = ({ input, meta, placeholder, cols = 32, rows = 5 }) => (
	<TextArea
		{...input}
		meta={meta}
		errorText={meta && meta.touched ? meta.error : ''}
		placeholder={placeholder}
		aria-label={placeholder}
		cols={cols}
		rows={rows}
	/>
)
//TODO: make proptypes for specific
TextAreaField.propTypes = {
	cols: PropTypes.number,
	input: PropTypes.any,
	meta: PropTypes.any,
	placeholder: PropTypes.any,
	rows: PropTypes.number
}

export default TextAreaField
